import Vue from 'vue'
import pinia from '@/store/store'
import VueRouter from 'vue-router'
import ImportantInformations from '@/components/ImportantInformations'
import DroppzOverview from '@/components/DroppzOverview'
import AktienOverview from '@/components/AktienOverview'
import UserCommands from '@/components/commands/UserCommands.vue'
import ChatGames from '@/components/ChatGames'
import PlayedGames from '@/components/PlayedGames'
import SongList from '@/components/SongList'
import HelpPrivacy from '@/components/HelpPrivacy'
import HouseData from '@/components/HouseData'
import MarblesGrandPrix from '@/components/MarblesGrandPrix'
import UserProfile from '@/components/user/UserProfile.vue'
import TwitchLoginHandler from '@/components/TwitchLoginHandler'
import ModMenu from '@/components/admin/ModMenu.vue'
import PageImpressum from '@/components/PageImpressum.vue'
import PayPalDonation from '@/components/PayPalDonation.vue'
import { userStore } from '@/store/user'
import { sendStatusUpdate } from '@/services/socketFunctions'

Vue.use(VueRouter, pinia)

const routes = [
  {
    path: '',
    component: ImportantInformations,
    name: 'InfoMain'
  },
  {
    path: '/info',
    component: ImportantInformations,
    name: 'Info'
  },
  {
    path: '/user_commands',
    component: UserCommands,
    name: 'UserCommands'
  },
  {
    path: '/chat_spiele',
    component: ChatGames,
    name: 'ChatGames'
  },
  {
    path: '/stream_spiele',
    component: PlayedGames,
    name: 'PlayedGames'
  },
  {
    path: '/droppz',
    alias: '/droppz_foogz',
    component: DroppzOverview,
    name: 'Droppz_Foogz'
  },
  {
    path: '/aktien',
    component: AktienOverview,
    name: 'Shares'
  },
  {
    path: '/songs',
    component: SongList,
    name: 'Songs'
  },
  {
    path: '/marbles_gp',
    component: MarblesGrandPrix,
    name: 'MarblesGrandPrix',
    alias: ['/murmeln', '/marbles']
  },
  {
    path: '/help',
    component: HelpPrivacy,
    name: 'Help'
  },
  {
    path: '/profile',
    component: UserProfile,
    name: 'UserProfile'
  },
  {
    path: '/twitch_login',
    component: TwitchLoginHandler,
    name: 'TwitchLoginHandler'
  },
  {
    path: '/house',
    component: HouseData,
    name: 'HouseData'
  },
  {
    path: '/mod_page',
    component: ModMenu,
    name: 'ModMenu'
  },
  {
    path: '/trinkgeld',
    component: PayPalDonation,
    name: 'Trinkgeld'
  },
  {
    path: '/impressum',
    component: PageImpressum,
    name: 'Impressum'
  },
  {
    path: '/support',
    component: () => import(/* webpackChunkName: "floppz_foundation" */ '@/components/FloppzFoundation.vue'),
    name: 'FloppzFoundation'
  },
  {
    path: '/regelwerke',
    component: () => import(/* webpackChunkName: "guidelines" */ '@/components/guidelines/GuideLines.vue'),
    name: 'Guidelines'
  },
  {
    path: '/*',
    component: () => import(/* webpackChunkName: "page_not_found" */ '@/components/404.vue'),
    name: '404Pager'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const userName = userStore().userData.login || 'no-login'
  sendStatusUpdate(to.path, userName)
  next()
})

export default router