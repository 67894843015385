import { defineStore } from 'pinia'
import axios from 'axios'

const webServer = process.env.VUE_APP_WEB_SERVER

export const droppzStore = defineStore('droppzStore', {
  state: () => {
    return {
      fetchInterval: null,
      userDroppz: {
        totaldroppz: '0',
        totaldiff: '0',
        jackpot: '0',
        jackdiff: '0',
        aktien: '0',
        lotto: '0'
      },
      chartDroppz: []
    }
  },
  actions: {
    fetchUserDroppzTimed() {
      console.log('fetching watchtime')
      clearTimeout(this.fetchInterval)
      this.fetchDroppzData().catch(() => {})
      this.fetchInterval = setTimeout(() => {
        this.fetchUserDroppzTimed()
      }, 1000 * 60 * 5)
    },
    async fetchUserDroppz() {
      const { data: jsonData } = await axios.get(`${webServer}/json/userdroppz.json`)
      if (jsonData) {
        this.userDroppz = jsonData
        this.userDroppz.user.forEach((userDroppz, index) => {
          userDroppz.index = index + 1
        })
      }
    },
    async fetchChartDroppz() {
      const { data: jsonData } = await axios.get(`${webServer}/json/droppz.json`)
      if (jsonData) {
        this.chartDroppz = jsonData
      }
    },
    async fetchDroppzData() {
      await this.fetchUserDroppz()
      await this.fetchChartDroppz()
    }
  },
  getters: {
    getUserDroppz: (state) => {
      return state.userDroppz.user
    }
  }
})